import { useState, useEffect, useCallback, useMemo } from "react"
import { useCore } from "@app/hooks/useCore"
import { useShop } from "@app/hooks/useShop"
import { useAppContext } from "@app/providers/app"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useConfigContext } from "@app/providers/config"
import { useCheckoutContext } from "@app/providers/checkout"
import deepEqual from "fast-deep-equal/react"

const useShopifyVariants = ({ firstAvailable = true, useParameter = false, loading = false, product }) => {
  const [activeVariant, setActiveVariant] = useState(null)

  const {
    helpers: { encodeShopifyId, decodeShopifyId, getUrlParameter, isBrowser, setUrlParameter },
  } = useCore()
  const { activeProduct } = useAppContext()
  const { checkout } = useCheckoutContext()
  const { shop } = useShop()
  const {
    settings: { params },
  } = useConfigContext()
  const { trackProductView } = useAnalytics()
  const { id, variants } = product || {}

  const getCurrentVariantId = useCallback(() => getUrlParameter(params?.variant), [getUrlParameter, params?.variant])
  const currentVariantId = useMemo(() => getCurrentVariantId(), [getCurrentVariantId])

  const defaultVariant = useMemo(
    () =>
      (useParameter && variants?.find(({ id }: { id: string }) => id === encodeShopifyId(currentVariantId, "ProductVariant"))) ||
      (firstAvailable && variants?.find(({ availableForSale }: { availableForSale: boolean }) => availableForSale)) ||
      variants?.[0],
    [currentVariantId, encodeShopifyId, firstAvailable, useParameter, variants]
  )

  const [selectedOptions, setSelectedOptions] = useState(defaultVariant?.selectedOptions || [])

  const handleOptions = useCallback(
    option => {
      const newOptions = selectedOptions?.map((selectedOption: any) => (selectedOption.name === option.name ? option : selectedOption))

      if (!deepEqual(newOptions, selectedOptions)) {
        setSelectedOptions(newOptions)
      }
    },
    [selectedOptions, setSelectedOptions]
  )

  useEffect(() => {
    if (
      (checkout?.currencyCode || shop?.currencyCode) &&
      !loading &&
      activeVariant &&
      !activeVariant?.priceV2?.amount &&
      defaultVariant?.priceV2?.amount &&
      !deepEqual(selectedOptions, defaultVariant?.selectedOptions)
    ) {
      setSelectedOptions(defaultVariant?.selectedOptions)
    }
    // Intentionally only run at selected times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout?.currencyCode, id, loading, shop?.currencyCode, variants?.length, defaultVariant?.selectedOptions])

  const replaceUrlParams = useCallback(
    newVariant => {
      const shouldRunEffect = useParameter && newVariant?.id && !loading && isBrowser

      if (shouldRunEffect) {
        const decodedVariantId = decodeShopifyId(newVariant.id, "ProductVariant")
        const shouldReplaceUrlParam = getCurrentVariantId() !== decodedVariantId

        if (shouldReplaceUrlParam) {
          window.history.replaceState(null, window.document.title, setUrlParameter(params.variant, decodedVariantId))
        }

        if (checkout?.currencyCode && newVariant?.id && newVariant?.priceV2?.amount && !loading) {
          trackProductView(activeProduct, newVariant, false)
        }
      }
    },
    [
      activeProduct,
      checkout?.currencyCode,
      decodeShopifyId,
      isBrowser,
      loading,
      params.variant,
      setUrlParameter,
      trackProductView,
      useParameter,
      getCurrentVariantId,
    ]
  )

  // intentionally exclude variants from dependencies.
  // only run if `selectedOptions` changes
  useEffect(() => {
    if (useParameter) {
      const newVariant =
        variants?.find(
          ({ selectedOptions: variantOptions }: { selectedOptions: any }) =>
            variantOptions?.filter(
              (variantOption: any) =>
                variantOption.value === selectedOptions.find((selectedOption: any) => selectedOption.name === variantOption.name)?.value
            )?.length === selectedOptions?.length
        ) || null

      if (!deepEqual(activeVariant, newVariant)) {
        setActiveVariant(newVariant)
        replaceUrlParams(newVariant)
      }
    } else {
      const newVariant =
        variants?.find(
          ({ selectedOptions: variantOptions }: { selectedOptions: any }) =>
            variantOptions?.filter(
              (variantOption: any) =>
                variantOption.value === selectedOptions.find((selectedOption: any) => selectedOption.name === variantOption.name)?.value
            )?.length === selectedOptions?.length
        ) || null

      if (!deepEqual(activeVariant, newVariant)) {
        setActiveVariant(newVariant)
        replaceUrlParams(newVariant)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions])

  return { activeVariant, handleOptions, selectedOptions, setActiveVariant }
}

export { useShopifyVariants }
