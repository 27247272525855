import { useCallback } from "react"
import { useLazyQuery } from "@apollo/client"
import { useCore } from "@app/hooks/useCore"
import { useConfigContext } from "@app/providers/config"
import { useCheckoutContext } from "@app/providers/checkout"
import { useShopify } from "./useShopify"

import type { ProductVariant } from "shopify-storefront-api-typings"

export const useShopifyProductLive = () => {
  const {
    graphql: {
      queries: { GET_PRODUCT_AVAILABILITY },
    },
    helpers: { isBrowser },
  } = useCore()
  const {
    store: { locationRegion },
  } = useConfigContext()
  const { countryCode } = useCheckoutContext()
  const { variantsNormaliser } = useShopify()

  const [getProduct, { data, called, loading, error }] = useLazyQuery(GET_PRODUCT_AVAILABILITY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  })

  if (error) console.error(error)

  const getLiveProduct = useCallback(
    (product: any) => {
      let liveProduct = undefined

      if (product.handle && countryCode && !called && isBrowser) {
        getProduct({ variables: { countryCode: countryCode || locationRegion, handle: product?.handle } })
      }

      try {
        liveProduct = data && {
          ...product,
          variants: product?.variants?.map((variant: ProductVariant, index: number) => ({
            ...variant,
            ...variantsNormaliser(data?.product?.variants)[index],
          })),
        }

        return { product: liveProduct, loading, error }
      } catch (err) {
        console.error("Error parsing live shopify product", err)
        return { product, loading, error }
      }
    },
    [called, countryCode, data, error, getProduct, loading, locationRegion, variantsNormaliser, isBrowser]
  )

  return { getLiveProduct }
}
