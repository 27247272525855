import { memo, useMemo, useState } from "react"
import { Box, Center, Container, Flex, HStack, Image, Stack, Divider } from "@chakra-ui/react"
import { ProductAccordionItems } from "./ProductAccordionItems"
import { ProductAddToCart } from "./ProductAddToCart"
import { useCore } from "@app/hooks/useCore"
import { ProductAfterPay } from "./ProductAfterPay"
import { BackButton } from "@components/BackButton"
import { ProductBrandLink } from "./ProductBrandLink"
import { RewardsProductPoints } from "@components/Rewards"
import { ProductPrice } from "./ProductPrice"
import { ProductRecommended } from "./ProductRecommended"
import { ProductReviews } from "./ProductReviews"
import { ProductReviewStars } from "./ProductReviewStars"
import { ProductTitle } from "./ProductTitle"
import { ProductVariants } from "./ProductVariants"
import { WishListButton } from "@components/WishList/WishListButton"
import { CustomLink } from "@components/Link"
import { ProductBonusMessaging } from "./ProductBonusMessaging"
import { usePageProduct } from "@app/hooks/usePageProduct"
import { ProductSubscriptionDetailsTooltip } from "./ProductSubscriptionDetailsTooltip"
import { useShopifyVariants } from "@app/hooks/useShopify"
import { useMedia } from "@app/hooks/useMedia"
import { ProductCardBadge } from "@components/ProductCard/ProductCardStyles"
import { useOutOfStock } from "@app/hooks/useOutOfStock"
import { ProductCardNotifyMe } from "@components/ProductCard/ProductCardNotifyMe"
import { useLocationContext } from "@app/providers/location"
import { useShopifyProductLive, useShopifyProductRaw } from "@app/hooks/useShopify"
import { useSubscriptions } from "@app/hooks/useSubscriptions"

import ProductMedia from "./ProductMedia"
import ProductSiblings from "./ProductSiblings"
import Sections from "@components/Sections/Sections"

import type { Props } from "@app/templates/product"
import type { PageProps } from "@root/types/global"

const buttonFontSizes = ["md", "xl"]

type ProductDisplayPageProps = {
  location: PageProps<Props>["location"]
  product: any
  template: PageProps<Props>["template"]
  productId?: string
  loading?: boolean
  sanityProduct: PageProps<Props>["product"]
  liveProductVariants?: any
}

type purchaseRecurrence = "monthly" | "one-time"

const ProductDisplayPage: React.FC<ProductDisplayPageProps> = ({
  location,
  product,
  template,
  productId,
  loading,
  sanityProduct,
  liveProductVariants,
}) => {
  const { brandCollection, brandCollectionUrl, isGiftCardProduct, isSubscriptionProduct, imageBadge, cardBadge } = usePageProduct({
    product,
    sanityProduct,
    productId,
  })

  const [purchaseRecurrence, setPurchaseRecurrence] = useState<purchaseRecurrence>("monthly")
  const { activeVariant, selectedOptions, handleOptions } = useShopifyVariants({ useParameter: true, product, loading })
  const { isBase } = useMedia()
  const { checkIfOutOfStock } = useOutOfStock()
  const activeVariantMatch = liveProductVariants?.find(v => btoa(v.id) === activeVariant?.id) || activeVariant
  const isOutOfStock = useMemo(() => checkIfOutOfStock({ variant: activeVariantMatch }), [activeVariantMatch, checkIfOutOfStock])
  const { subscriptionDinkus } = useSubscriptions()

  const subscriptionType = (isSubscriptionProduct as any)?.subscription_defaults?.storefront_purchase_options || null
  const isSubscriptionAndOneTimeProduct = subscriptionType === "subscription_and_onetime"

  const subscriptionDiscountPercentage = Number((isSubscriptionProduct as any)?.discount_amount) || undefined
  const subscriptionDinkusMessage = subscriptionDinkus?.dinkusMessage?.replace(`{{X%}}`, `${subscriptionDiscountPercentage}%`)

  const listPrice = activeVariant?.compareAtPriceV2?.amount || 0
  const salePrice = activeVariant?.priceV2?.amount || 0

  const calculatePercentageDiscount = (listPrice: number, salePrice: number) => {
    const discount = listPrice - salePrice
    const discountPercentage = (discount / listPrice) * 100

    return Math.round(discountPercentage)
  }

  const discountPercentage = listPrice && salePrice ? `Save ${calculatePercentageDiscount(listPrice, salePrice)}%` : null

  return (
    <>
      <Container as="section" pt={[0, 12, 21]} pb={[15, 20]} maxW={["unset", "unset", "1290px"]} px={[0, 0, 4]}>
        <HStack bg={["brand.midSand", "unset"]} mb={[0, 4]} py={[2, 0]} px={[5, 5, 0]} justifyContent="space-between">
          <BackButton label={template?.additionalBackLinkLabel} fallback="/collections/new" />
          {isBase && (
            <Box w={40}>
              <WishListButton
                location={location}
                product={product}
                fontSize={buttonFontSizes}
                iconTextGap={2.5}
                height={7}
                showBgColor={false}
                showOutline={false}
                showUnderline={true}
                variant={activeVariant}
              />
            </Box>
          )}
        </HStack>
        <Flex flexDirection={["column", "column", "row"]}>
          <Box w={["100%", "100%", "56%"]} pr={[0, 0, 4, 8]} position="relative">
            <ProductMedia product={product} brandCollection={brandCollection} />
            {isSubscriptionAndOneTimeProduct ? (
              <ProductCardBadge bgColor="yellow" text={subscriptionDinkusMessage} badgeType="image" isPdp tagVariant="pdp-image" />
            ) : (
              imageBadge?.showBadge && (
                <ProductCardBadge
                  bgColor={imageBadge?.badge?.bgColor}
                  text={imageBadge?.badge?.text}
                  badgeType="image"
                  isPdp
                  tagVariant="pdp-image"
                  discountPercentage={discountPercentage}
                />
              )
            )}
          </Box>
          <Box w={["100%", "100%", "40%"]} pl={[4, 4, 8]} px={4}>
            <ProductBrandLink title={brandCollection?.title} url={brandCollectionUrl} />
            <ProductTitle product={product} />
            {!loading && product && (
              <Box mb={[4, 6]}>
                <ProductReviewStars product={product} showLink={true} size="large" productId={productId} />
              </Box>
            )}
            {!isGiftCardProduct && (
              <HStack alignItems="flex-start" spacing={2}>
                <ProductPrice
                  variant={activeVariant}
                  appliedDiscount={purchaseRecurrence === "monthly" ? subscriptionDiscountPercentage : 0}
                />
                {isSubscriptionAndOneTimeProduct ? (
                  <ProductCardBadge bgColor="yellow" text={subscriptionDinkusMessage} badgeType="card" isPdp tagVariant="pdp-card" />
                ) : (
                  cardBadge?.showBadge && (
                    <ProductCardBadge
                      bgColor={cardBadge?.badge?.bgColor}
                      text={cardBadge?.badge?.text}
                      badgeType="card"
                      isPdp
                      tagVariant="pdp-card"
                    />
                  )
                )}
              </HStack>
            )}
            <ProductVariants
              product={product}
              selectedOptions={selectedOptions}
              handleOptions={handleOptions}
              loading={loading}
              isGiftCardProduct={isGiftCardProduct}
              isSubscriptionAndOneTimeProduct={isSubscriptionAndOneTimeProduct}
              recurrence={{ purchaseRecurrence, setPurchaseRecurrence }}
              appliedDiscount={subscriptionDiscountPercentage}
            />
            <ProductSiblings title="Colours" product={product} siblings={sanityProduct?.siblings} loading={loading} />
            {sanityProduct?.bonus && <ProductBonusMessaging bonus={sanityProduct?.bonus} />}
            <HStack spacing={5} my={5} h={4}>
              {isSubscriptionProduct && (
                <>
                  <ProductSubscriptionDetailsTooltip />
                  <Divider orientation="vertical" borderColor="brand.avocado" />
                </>
              )}
              <RewardsProductPoints productId={productId} variant={activeVariant} />

              {!isSubscriptionProduct && (
                <>
                  <Divider orientation="vertical" borderColor="brand.avocado" />
                  <ProductAfterPay />
                </>
              )}
            </HStack>
            <Stack direction={["column", "column", "column", "row"]}>
              <Box w={["full", "full", "full", isSubscriptionProduct ? "full" : "half"]}>
                {isOutOfStock && !isGiftCardProduct ? (
                  <ProductCardNotifyMe variant={activeVariant} fontSize={buttonFontSizes} h={12} />
                ) : (
                  <ProductAddToCart
                    loading={loading}
                    isSubscriptionProduct={isSubscriptionProduct}
                    height={12}
                    fontSize={buttonFontSizes}
                    variant={activeVariant}
                    subscriptionData={{
                      isSubscriptionAndOneTimeProduct,
                      purchaseRecurrence,
                      subscriptionDiscountPercentage,
                    }}
                  />
                )}
              </Box>
              {!isSubscriptionProduct && (
                <Box w={["full", "full", "full", "half"]}>
                  <WishListButton
                    location={location}
                    product={product}
                    fontSize={buttonFontSizes}
                    height={12}
                    iconTextGap={[2.5, 3]}
                    variant={activeVariant}
                  />
                </Box>
              )}
            </Stack>
            {!!brandCollection?.image?.src && (
              <Center mt={6} display={["flex", "flex", "none"]}>
                <CustomLink to={brandCollectionUrl}>
                  <Image src={brandCollection?.image?.src} alt={brandCollection?.image?.alt} w={24} />
                </CustomLink>
              </Center>
            )}
            <ProductAccordionItems product={product} tabItems={sanityProduct?.tabItems} />
          </Box>
        </Flex>
      </Container>
    </>
  )
}

const Product: React.FC<PageProps<Props>> = ({ location, product: sanityProduct, template }) => {
  const {
    helpers: { decodeShopifyId, isBrowser },
  } = useCore()
  const { getRawProduct } = useShopifyProductRaw()
  const { getLiveProduct } = useShopifyProductLive()

  const { locating } = useLocationContext()
  const { product: rawProduct } = useMemo(() => getRawProduct(sanityProduct), [getRawProduct, sanityProduct])
  const { product: liveProduct, loading } = useMemo(
    () => (rawProduct ? getLiveProduct(rawProduct) : { product: null, loading: false }),
    [getLiveProduct, rawProduct]
  )

  const isLoading = useMemo(() => isBrowser && (loading || locating), [isBrowser, loading, locating])

  const product = useMemo(() => liveProduct || rawProduct, [liveProduct, rawProduct])

  const productId = useMemo(() => decodeShopifyId(product?.id, "Product"), [product?.id, decodeShopifyId])

  return (
    <>
      <ProductDisplayPage
        liveProductVariants={liveProduct?.variants}
        location={location}
        product={product}
        template={template}
        loading={isLoading}
        productId={productId}
        sanityProduct={sanityProduct}
      />
      {sanityProduct?.sections && <Sections page={{ content: sanityProduct?.sections }} />}
      {product && <ProductReviews product={product} productId={productId} />}
      {!isLoading && product && <ProductRecommended id={product.id} location={location} />}
    </>
  )
}

export default memo(Product)
